.ask-service-modal {

    &-header {
        background-color: #C0E3F4;
        border: 0;

        .btn-close {
            filter: invert(1);
            opacity: 1;
            width: 4em;
            height: 3em;
        }
    }

    &-body {
        background-color: #C0E3F4;
        padding: 5rem 10rem;
    }

}
.ask-modal-size{
    max-width: 95rem;
}