@include mq('sm', 'max') {
    .header {
        .header-nav {
            .navbar {
                margin-top: -6rem;
                justify-content: end;

            }

            .active {
                &::before {
                    content: "";
                    border: 0 !important;
                }
            }

            .navbar-collapse {
                padding-top: 3rem;

            }
        }
    }

    .footer {
        &::after {
            background-image: none;
        }

        &::before {
            background-image: none;
        }

        .footer-menu {
            margin: unset;
        }
    }

    .hero {
        padding: 5rem 0 !important;

        .hero-sub-header {
            &::after {
                right: 0;
            }
        }
    }



    .ask-for-service {
        .ask-for-service-container {
            padding: 2rem;
        }
    }

    .about-us {
        .about-us-content {
            margin-top: 10rem;
        }

        .about-us-img {
            img {
                height: auto;
            }
        }

        .about-us-right-side {
            border-radius: 0;

            .about-us-right-side-items {


                .about-us-right-side-item {
                    width: auto;


                }
            }
        }
    }

    .our-products-slider {
        background-size: cover;
    }


    .section2-header {
        font-size: 3rem;
    }

    .section-title {
        width: auto;

        &::after {
            right: 0;
        }
    }

    .ask-service-modal-body {
        padding: 1rem 2rem;
    }

    .jobs {
        .page-header {
            background-image: none;
        }
    }

    .services {
        .services-thanks-container {
            .services-thanks {
                min-height: 32rem !important;
                height: unset;
            }
        }
    }

    .about-us {
        .about-us-thanks-container {
            .about-us-thanks {
                min-height: 32rem !important;
                height: unset;
            }
        }
    }

    .faq {
        .accordion-collapse {
            padding-inline-start: 1rem;
        }
    }
}


@include mq('lg', 'max') {
    .header {
        .header-nav {
            .navbar {
                .navbar-nav {
                    a {
                        font-size: 1.2rem !important;
                        color: #FFF;
                    }
                }

                .navbar-collapse {
                    min-height: 50px !important;
                    background: $primary-color;
                    padding: 2rem;
                    justify-content: flex-start;
                }
            }

            .nav-link {
                &.active {

                    &::before {
                        bottom: -13px;
                    }
                }
            }
        }
    }

    .hero {
        padding: 20rem 0;

        .hero-content {
            .hero-sub-header {
                .business {
                    &::before {
                        left: unset;
                        right: 4rem;
                    }
                }
            }
        }

        &::before {
            display: none;
        }

        .hero-img {
            .it-img {
                margin: auto;
            }
        }
    }

    .about-us {
        .about-us-content {
            margin-top: 10rem;
        }

        .about-us-img {
            img {
                height: auto;
            }
        }

        .about-us-right-side {

            .about-us-right-side-items {


                .about-us-right-side-item {}
            }
        }

        .about-us-thanks-container {
            .about-us-thanks {
                height: auto !important;

                .about-us-thanks-img {
                    top: -5rem;
                }
            }
        }
    }

    .services {
        .services-thanks-container {
            .services-thanks {
                height: auto !important;
                .services-thanks-img{
                    top: 0;
                    right: inherit;
                }
            }
        }
    }
    .jobs{
        .page-header{
            background-size: cover;
        }
    } 

    .join-our-network {
        .container{
            .btn-primary{
                top: 1rem;
            }
        } 
    }
    

    .our-products-slider {
        background-size: cover;
    }


    .section2-header {
        font-size: 3rem;
    }

    .section-title {
        width: auto;

        &::after {
            right: 0;
        }
    }


}

@include mq('xl', 'max') {
    .our-technologies {
        .technology-block {
            min-width: 390px;
        }
    }

    .work-process {
        .start-project-area {
            width: 792px;
            padding-left: 2rem;
        }
    }

    .section-title {
        width: auto;

        &::after {
            right: 0;
        }
    }
}