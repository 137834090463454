// Variables
$font-size: 1.4rem !default;
$sidebar-font-size: 1.4rem !default;
$footer-font-size: 1.3rem !default;
$input-font-size: 1.4rem !default;
$button-font-size: 1.4rem !default;

$primary-color: #184d76  !default;
$secondary-color: #ef6d58 !default;
$link-color: $primary-color !default;
$main-orange : #FA8724;
$lazy-image-background: #ebebeb;

$border-radius: .8rem !default;

$body-text: #201F20 !default;
$light-text: #999999 !default;
$dark-text: #333333 !default;
$headings-text: $dark-text !default;


$body-bg-dark-color: #161920;
$body-bg-light-color: #FFF;

$header-bg-dark-color: #0F1013;
$header-bg-light-color: #FFF;


$font-family: 'Poppins', sans-serif !default;
$second-font-family: 'Roboto', sans-serif;
$arabic-font-family: 'dinnext', sans-serif !default;

$breakpoints: (
	xs: 480px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1600px,
	xlst: 1920px
);

$max-breakpoints: (
	xs: 479px,
	sm: 575px,
	md: 767px,
	lg: 991px,
	xl: 1199px,
	xxl: 1599px
);