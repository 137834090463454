.jobs {
    .page-header {
        background-color: #C0E3F4;
        width: 100%;
        min-height: 50rem;
        overflow: hidden;
        background-image: url(/images/jobs-header-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        padding-bottom: 5rem;
    }
}

.job-item {
    border: 1.5px solid #434343;
    border-radius: 1rem;
    text-align: center;
    padding: 3rem;
    margin-bottom: 2rem;

    &-icon {
        width: 12rem;
        height: 12rem;
        margin: 3rem auto;
    }

    &-title {
        font-weight: 600;
    }

    .btn-primary {
        width: 100%;
        height: 5rem;
        border: 1px solid #000;
        background-color: transparent;
        color: #000;
        margin-top: 2rem;

        &:hover {
            color: #FFF;
            background-color: #000;
        }
    }
}