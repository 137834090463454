.footer {
    padding-top: 5rem;
    position: relative;
    overflow: hidden;

    .footer-title {
        font-size: 2.8rem;

    }

    .footer-links {
        display: flex;
        flex-direction: column;

        a {
            font-size: 1.6rem;
            font-weight: 300;
            color: #000;
        }
    }

    .footer-social-links {
        display: flex;
        gap: 3rem;
        margin-top: 3rem;
        padding: 0 .2rem;
    }

    .footer-contact-link {
        font-size: 1.8rem;
        color: $primary-color;
        margin-top: 1rem;
        margin-inline-end: .6rem;
    }

    .footer-bottom {
        width: 100%;
        min-height: 16rem;
        background-color: #11131B;

        &-links {
            width: 100%;
            min-height: 14rem;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                font-size: 2.2rem;
                font-weight: 300;
                color: #FFF;
            }
        }


    }

    .footer-bottom-copy {
        background-color: #141720;

        .footer-bottom-line {
            height: 14rem;
            display: flex;
            align-items: center;

            a {
                font-size: 1.8rem;
                color: rgba($color: #FFFFFF, $alpha: .3);
            }

            .copyright {
                p {
                    font-size: 1.7rem;
                    font-weight: 500;
                }
            }
        }


    }
}