.about-us {
    .page-header {
        background-color: #C0E3F4;
        width: 100%;
        min-height: 55rem;
        overflow: hidden;

        .about-us-header {
            font-size: 4.6rem;
            font-weight: bold;
            color: #000;
        }

        .about-us-text {
            color: #000;
            font-size: 1.6rem;
            font-weight: 400;
        }

        .btn-primary {
            width: 16rem;
            height: 5rem;
            background-color: #000;
            border: 1px solid #000;
            border-radius: 2rem;
        }

        .form-control {
            background-color: transparent;
            border: 1px solid #90A4AE;
            padding-inline-start: 2rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-radius: 1rem;
        }
    }

    .about-us-content {
        .about-us-header {
            font-size: 3.6rem;
            font-weight: bold;
            margin-top: 10rem;
        }
    }

    .about-us-thanks-container {
        background-color: #FFF;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(#C0E3F4, .4) 100%);
        padding: 20rem 0;

        .about-us-thanks {
            background-color: $primary-color;
            border-radius: 3rem;
            height: 32rem;
            padding: 5rem;

            &-header {
                font-size: 2.6rem;
                font-weight: bold;
                color: #FFF;
                text-align: center;
            }

            .about-us-thanks-img {
                position: relative;
                top: -13rem;
            }

            .btn-primary {
                width: 19rem;
                height: 4.5rem;
                border-radius: 4rem;
                border: 1px solid #FFF;
                background-color: rgba($color: $primary-color, $alpha: .2);
                margin-top: 2rem;
            }
        }
    }

}