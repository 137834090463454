/* Type */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 1.4rem;
  color: $headings-text;
  letter-spacing: -.03em;
}

h1, .h1 {
  font-size: 4rem;
}

h2, .h2 {
  font-size: 3.6rem;
}

h3, .h3 {
  font-size: 2.6rem;
}

h4, .h4 {
  font-size: 2.2rem;
}

h5, .h5 {
  font-size: 2rem;
  letter-spacing: -.025em;
}

h6, .h6 {
  font-size: 1.6rem;
  letter-spacing: -.01em;
}

a {
  color: $link-color;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover,
a:focus {
  color: darken($link-color, 10%);
  text-decoration: none;
}

.link-underline {
  box-shadow: 0 1px 0 0 $primary-color;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-dark {
  color: #333 !important;
}

.text-light {
  color: #ccc !important;
}

.lead {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.875;
  margin-bottom: 1.2rem;
}

blockquote {
  position: relative;
  margin: 0 0 4.2rem;
  padding: 0;
  color: #777;
  font-style: italic;
  font-size: 1.6rem;
  line-height: 1.625;

  p {
    max-width: 1120px;
  }

  &.text-center p {
    margin-left: auto;
    margin-right: auto;
  }

  cite {
    font-style: normal;
    font-weight: 500;
    color: #333333;
    letter-spacing: -.01em;
    line-height: 1;

    span {
      display: block;
      font-weight: 300;
      font-size: 1.4rem;
    }
  }

  &.text-white {
    cite {
      color: #cccccc;
      span {
        color: #777;
      }
    }
  }
}

@include mq('md') {
  h1, .h1 {
    font-size: 5rem;
  }

  h2, .h2 {
    font-size: 4rem;
  }

  h3, .h3 {
    font-size: 3rem;
  }

  h4, .h4 {
    font-size: 2.4rem;
  }
  .rtl{
    h4, .h4 {
      font-size: 2.4rem;
      text-align: right;
    }
  }
}

@include mq('lg') {
}