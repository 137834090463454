.dark {
    .hero {
        background: #002B29;
        background: linear-gradient(90deg, #031126 0%, #002B29 100%);
    }

    .header {
        &.fixed {
            background: #002B29;
            background: linear-gradient(90deg, #031126 0%, #002B29 100%);
        }

        // .header-nav {
        //     .navbar {
        //         .navbar-collapse {
        //             background: linear-gradient(90deg,  #031126 0%,  #002B29 100%);
        //         }
        //     }
        // }
        .condition {
            p {
                color: #F2F2F2;
            }
        }
    }

    .section-header {
        color: #F2F2F2;
    }

    .section-text {
        color: #AEAFB0 !important;
    }

    .about-us {
        .about-us-content {

            .our-mission,
            .ceo-text,
            .ceo-title {
                color: #F2F2F2;
            }

            .ceo-name {
                img {
                    filter: brightness(8)
                }
            }
        }

    }

    .our-services {
        .service-item {
            background-color: #25242D;
            color: #F2F2F2;

            &::after {
                background-color: #25242D;
            }
        }
    }

    .business-sectors {
        .sector-item {
            color: #F2F2F2;

            &::after {
                background-color: #201F20;

            }

            &.odd {
                &::after {
                    background-color: #04262C !important;
                }
            }

            &:hover {
                .sector-icon {
                    &::before {
                        border: 1px dashed rgb(121, 121, 121);
                    }
                }
            }
        }
    }

    .our-technologies {
        .technology-title {
            color: #AEAFB0;
        }

        .technology-item {
            border-color: #56555B;
            background-color: #161920;

            .technology-icon {
                background-color: #FFF;
            }
        }
    }

    .work-process {
        background: #04262C;

        .process-item {
            .process-title {
                color: #F2F2F2;
            }

            .process-text {
                color: #AEAFB0;
            }
        }
    }

    .partners {
        background-color: #181820;

        .slick-slide {
            img {
                filter: brightness(0) invert(1);
            }
        }
    }


    .lets-talk {
        background: #002B29;
        background: linear-gradient(90deg, #031126 0%, #002B29 100%);

        .got-a-project {
            background-color: #05040E;

            .about-project,
            .brief,
            .we-are {
                color: #858589;
            }

            .got-a-project-title {
                color: #F2F2F2;
            }

            .form-group {
                label {
                    color: #F2F2F2;
                }
            }
        }

        .section-text {
            color: #f2f2f2 !important;
        }
    }

    .about-us {
        .about-us-content {
            color: #F2F2F2;

            .about-us-text {
                color: #F2F2F2;
            }
        }

        .our-features {
            .feature-item {
                color: #F2F2F2;

                &::after {
                    background-color: #25242D;
                }
            }
        }

        .brochure {
            background-color: #04262C;

            .brochure-content {
                .brochure-title {
                    color: #F2F2F2;
                }
            }

            &::before {
                opacity: 0.1;
            }
        }
    }

    .portfolio {
        .react-tabs__tab {
            color: #f2f2f2;

            &:focus {
                &::after {
                    background: #25242D;
                }
            }
        }

    }

    .portfolio-detail {
        .portfolio-text {
            color: #f2f2f2;
        }

        .portfolio-content {
            color: #f2f2f2;

        }

        .portfolio-dates {
            .portfolio-date {
                h3 {
                    color: #F2F2F2;
                }

                p {
                    span {
                        color: #F2F2F2;

                    }
                }
            }
        }

        .portfolio-infos {
            h5 {
                color: #F2F2F2;
            }
        }

        .portfolio-deliverables {
            h3 {
                color: #F2F2F2;
            }

            ul {
                li {
                    color: #F2F2F2;

                }
            }

            .visit-website {
                a {
                    color: #00ECDE;
                }
            }
        }
    }


    .form-control {
        background-color: #05040E !important;
        border-color: #56555B !important;
        color: #858589 !important;
    }

    .form-select {
        background-color: #05040E !important;
        border-color: #56555B !important;
        color: #FFF !important;
        background-image: url(/images/icons/white-select-arrow-down.svg) !important;
    }

    .page-header {
        &.sub-page {
            .page-header-content {
                color: #F2F2F2;
            }
        }
    }

    .footer {
        background: #161920;

        .footer-text {
            p {
                color: #F2F2F2;
            }
        }

        .copyright {
            border-color: #56555B;

            p {
                color: #F2F2F2;
            }
        }


        .footer-logo {
            img {
                filter: brightness(0) invert(1);
            }
        }

        .footer-menu {
            .footer-nav {
                ul {
                    li {
                        a {
                            color: #F2F2F2;
                        }
                    }
                }
            }

            .footer-nav-title {
                h6 {
                    color: #F2F2F2 !important;
                }
            }
        }

    }

    .btn-gradient {
        background: #002B29;
        background: linear-gradient(90deg, #031126 0%, #002B29 100%);
        border: 1px solid #56555B;
        border-color: #56555B !important;
    }

    .blog-detail-title,
    .blog-item-description,
    .blog-item-title {
        color: #FFF;
    }

    .blog-detail-description {

        p,
        span {
            color: #FFF !important;
        }
    }

    .blog-detail {
        h4 {
            color: #FFF;
        }
    }

    .page-header {
        &.sub-page {
            .page-header-content {
                .page-header-breadcrumb {
                    ul {
                        li {
                            img {
                                filter: brightness(1);
                            }
                        }
                    }
                }
            }
        }
    }
}

.light {
    .section-header {
        color: #201F20;
    }

    .section-text {
        color: #201F20;
    }

    .footer {
        background: #FFF;
    }

    .condition {
        p {
            color: #FFF;
        }
    }
}