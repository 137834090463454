/********** 1. Hero Section **********/
.hero {
    .hero-header {
        font-size: 3.2rem;
        letter-spacing: 4.11px;
        color: $primary-color;
    }

    .hero-sub-header {
        font-size: 5.8rem;
        line-height: 1.33;
        letter-spacing: -1.67px;
        z-index: 1;
        margin-bottom: 3rem;
        position: relative;
        color: $primary-color;
        &::after {
            content: "";
            position: absolute;
            bottom: -2rem;
            right: -32rem;
            background-image: url(/images/underline.png);
            background-repeat: no-repeat;
            width: 100%;
            height: 1.5rem;
        }
    }

    .hero-text {
        font-family: 1.7rem;
    }

    .custom-arrows {
        position: absolute;
        top: 0;
        right: 0;
        width: 12rem;
        height: 100%;
        background-color: #141720;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-buttons {
            width: 12rem;
            display: flex;
            flex-direction: column;
            gap: 15rem;
            position: relative;
            z-index: 100;
        }
    }

    .slick-dots {
        top: 46%;
        transform: translateX(-46%);
        text-align: right;
        right: 2.5rem;
        z-index: 100;
        width: auto;
        display: flex !important;
        flex-direction: column;

        li {
            button {
                &::before {
                    color: #979797 !important;
                    font-size: 1.3rem;
                }
            }
        }

        .slick-active {
            button {
                &::before {
                    color: #979797 !important;
                    font-size: 1.3rem;
                }
            }
        }
    }

    .hero-counts {
        background-color: #f6f6f6;
        border: 1px solid #979797;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 2rem;

        .hero-count {
            font-size: 5.4rem;
            font-family: $second-font-family;
            font-weight: 700;
        }

        .hero-count-text {
            font-size: 2.2rem;
            color: #5e5e5e;
        }

        &.active {
            background-color: $primary-color;
            border: 1px solid #5994b0;

            .hero-count,
            .hero-count-text {
                color: #FFF;
            }
        }
    }
}

/********** 2. Our Products Section **********/
.our-products-slider {
    background: url(/images/our-product-slider-bg.png);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 34rem;
    border-radius: 2.5rem;
    padding: 0;
    border: 1px solid #FFF;




    .our-products-slider-item {
        text-align: center;
        padding: 0 7rem;
        border-inline-end: 1px solid #fff;

        &-icon {
            img {
                margin: 1rem auto;
            }
        }

        &-header {
            color: #FFF;
            font-size: 2.2rem;
            font-weight: bold;
        }

        &-text {
            color: #FFF;
            font-weight: 300;
            font-size: 1.5rem;
            min-height: 11rem;
        }

        &-read-more {
            font-size: 1.6rem;
            text-transform: uppercase;
            color: #FFF;
        }
    }
}

/********** 3. Service Section **********/
.service {
    .service-item {
        padding: 4.8rem 2.7rem 3.6rem 2.6rem;
        border-radius: .8rem;
        border: solid 1px #434343;
        background-color: #fff;
        margin: 2rem;

        &-header {
            color: #262b36;
            margin: 1rem 0;
        }

        &-text {
            margin-bottom: 3rem;
            color: #606b86;
        }

        &-read-more {
            width: 100%;
            height: 4.8rem;
            border: 1px solid #0a0a0a;
            color: #0a0a0a;
            padding: 1rem 3rem;
            border-radius: .8rem;

            &:hover {
                color: #FFF;
                background-color: #0a0a0a;
            }
        }
    }
}

/********** 4. About Section **********/
.about-us {


    .about-us-read-more {
        font-size: 2rem;
        font-weight: 600;
        color: #4a4a4a;
        border-bottom: 1px solid #404040;
        padding-bottom: .1rem;
    }

    .about-us-right-side {
        background-color: $primary-color;
        border-bottom-left-radius: 20rem;

        .about-us-right-side-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 10rem;

            .about-us-right-side-item {
                width: 40rem;
                display: flex;
                gap: 3rem;
                color: #FFF;
                margin-bottom: 5rem;
                position: relative;
                z-index: 101;

                &-header {
                    font-weight: 600;
                }

                &-text {
                    font-weight: 300;
                }
            }
        }
    }
}

/********** 5. Ask For Service Section **********/
.ask-for-service {

    .ask-for-service-container {
        background-color: #c0e3f4;
        border: 1px solid #6aa7c1;
        border-radius: 2.2rem;
        width: 100%;
        min-height: 45rem;
        padding: 5rem 10rem;
        display: flex;
        align-items: center;

        .form-control {
            width: 100%;
            height: 5rem;
            margin-bottom: 2rem;
            padding-inline-start: 2rem;
            border-radius: 1rem;

            &::placeholder {
                color: #959595;
            }
        }

        .btn-secondary {
            border-radius: 1rem;
            letter-spacing: 4px;
            font-weight: 300;
        }
    }
}

/********** 6. Ask For Service Section **********/
.video-area {
    background-image: url(/images/video-area-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: .4);
        width: 100%;
        height: 100%;
    }

    &-title {
        font-size: 5rem;
        font-weight: 300;
        color: #FFF;
    }

    .container {
        position: relative;
        z-index: 100;
    }

    &-header {
        font-size: 3.2rem;
        color: $secondary-color;
    }

    &-button {
        margin: 5rem auto;
        position: relative;
        width: 10rem;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 14rem;
            height: 14rem;
            background-color: rgba($color: #FFF, $alpha: .2);
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 17rem;
            height: 17rem;
            background-color: rgba($color: #FFF, $alpha: .1);
        }
    }
}

/********** 7. Why Working Section **********/
.why-working {
    .why-working-item-header {
        font-weight: 400;
        font-size: 2rem;
    }

    .why-working-item-text {
        color: #1f1f1f;
    }
}

/********** 8. Why Working Section **********/
.projects-slider {
    .projects-item {
        padding: 0 2rem;

        &-title {
            font-size: 2.2rem;
            font-weight: bold;
            margin: 1rem 0;
        }

        &-text {
            font-size: 1.4rem;
        }

        &-image {
            position: relative;
        }

        &-location {
            width: 80%;
            position: absolute;
            bottom: 2rem;
            left: 35%;
            transform: translateX(-35%);
            color: #FFF;
            background-color: $primary-color;
            border-radius: 3rem;
            padding: 1.5rem 2.5rem;
            display: flex;
            gap: 1rem;
        }

        &-readmore {
            text-align: end;
            font-weight: bold;
            font-size: 1.8rem;

            a {
                color: #4A4A4A;
                text-decoration: underline;
            }
        }
    }
}

/********** 9. Clients Section **********/
.clients {
    .client-item {
        width: 26rem !important;
        height: 15rem;
        display: flex !important;
        align-items: center;
        justify-content: center;

        img {
            object-fit: cover;
        }
    }
}


/********** 10. Testimonies Section **********/
.testimonies {
    background-color: #FFF;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(#C0E3F4, 1) 100%);
    margin-top: 5rem;

    .testimonies-text {
        font-size: 4.2rem;
        font-weight: bold;
        color: #262B36;
    }

    .testimonie-item {
        background-color: #FFF;
        border-radius: 2.4rem;
        padding: 3rem;
        margin: 5rem 1rem;
        position: relative;

        &-image {
            position: absolute;
            right: 0;
            top: -4rem;
        }

        .testimonie-item-review-text {
            margin-bottom: 3rem;
            font-weight: 300;
            font-size: 2rem;
        }

        .testimonie-item-review-position {
            color: #606B86;
            font-size: 1.5rem;
        }
    }
    .slick-prev{
        left: -4rem !important;
    }
    .slick-next{
        right: -2rem !important;
    }
}