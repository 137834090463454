.section-title {
    color: $primary-color;
    font-size: 5rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.7;
    position: relative;
    width: max-content;
    margin: auto;

    &::after {
        content: "";
        position: absolute;
        bottom: -1.5rem;
        right: -11rem;
        background-image: url(/images/underline.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 34rem;
        height: 2rem;
    }
}

.section-text {
    font-size: 2.2rem;
    line-height: 1.57;
    letter-spacing: -0.35px;
    text-align: center;
    color: #494949;
    margin: 2rem 0;
}

.section2-title {
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 5px;
    color: $secondary-color;
    text-transform: uppercase;
}

.section2-header {
    font-size: 5rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: -1px;
    color: #202020;
    margin: 1rem 0;
}

.section2-text {
    font-size: 1.6rem;
    color: rgba(70, 70, 70, 0.64);
    width: 70%;
}

section {
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: relative;
}

.go-top {
    width: 45px;
    height: 45px;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 9999;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba($primary-color, 1) 0%, rgba($secondary-color, 1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        position: relative;
        top: -.2rem;
    }
}

.btn {
    font-size: 1.4rem;
    border-radius: 1rem;
    padding: 1rem 2rem;
    height: 4.5rem;
    width: max-content;
}

.btn-primary {
    font-size: 1.5rem;
    transition: none;
    position: relative;
    overflow: hidden;
    background-color: $primary-color;
    height: 4.5rem;

    &:focus {
        border-color: $primary-color !important;
        background-color: $primary-color;
        box-shadow: 0 0 0 0.25rem rgba(1, 144, 136, .51);
    }

    span {
        position: relative;
        z-index: 10;
    }
}

.btn-secondary {
    font-size: 1.5rem;
    background-color: $secondary-color;
    border-color: $secondary-color;
    height: 4.5rem;
    border-radius: 2.2rem;
    line-height: 3.2rem;
    padding: .5rem 2rem;
}

.form-control {
    font-size: 1.4rem !important;
}

.other-page {
    .page-header {
        background-color: #C0E3F4;
        width: 100%;
        height: 30rem;
        overflow: hidden;
        display: flex;
        align-items: center;
    }
}

.ReactModal__Overlay {
    z-index: 1100;
}

.swal2-html-container {
    font-size: 1.6rem !important;
}

.swal2-styled {
    &.swal2-confirm {
        font-size: 1.4rem;
    }
}

.swal2-title {
    font-size: 2.4rem;
}

.swal2-popup {
    width: 40em !important;
}

.our-products-slider,
.service-slider {

    .slick-slider {
        margin-top: 4rem;

        .slick-dots {
            bottom: -8rem;

            li {
                button {
                    &::before {
                        border: solid 2px #979797;
                        background-color: #d8d8d8;
                        color: transparent;
                        border-radius: 50%;
                        overflow: hidden;
                        width: 1.2rem;
                        height: 1.2rem;
                    }
                }
            }

            .slick-active {
                button {

                    &::before {
                        color: transparent;
                        background-image: linear-gradient(to bottom, #ef6d58, #b86e53);
                        border-radius: 50%;
                        overflow: hidden;
                    }
                }
            }

        }
    }
}

.slick-next:before,
.slick-prev:before {
    content: "" !important;
}

.safety-precautions {

    &-body {
        border: 1px solid $primary-color;
        margin-top: 3rem;
        padding: 2rem 4rem;
        border-radius: 3rem;

        h4,
        p,
        a {
            font-weight: 400;
        }

    }
}