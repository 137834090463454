/*** RTL FIX ***/
.rtl {
    text-align: right;



    .header {
        .header-nav {
            .navbar {
                .navbar-nav {
                    a {
                        font-size: 1.8rem;
                    }

                    width: 90%;
                }
            }
        }
    }

    .hero {

        .hero-content {
            width: 85%;

            .hero-sub-header {
                .business::before {
                    width: 153px;
                    background-size: contain;
                    bottom: -13px;
                }
            }
        }

        &::before {
            right: unset;
            left: -90rem;
            transform: rotate(180deg);

        }
    }

    .go-top {
        right: unset;
        left: 1rem;
    }

    .form-select {
        background-position: left 0.75rem center !important;
    }

    .about-us {

        .about-us-img {
            transform: rotateY(180deg);
        }

        .about-us-content {
            img {
                transform: rotateY(180deg);
            }
        }

        .about-us-thanks-container {
            .about-us-thanks {
                .about-us-thanks-img {
                    right: unset;
                    left: -20rem;
                }
            }
        }
    }

    .services {
        .services-thanks-container {
            .services-thanks {
                .services-thanks-img {
                    right: unset;
                    left: -20rem;
                    transform: rotateY(180deg);
                }
            }
        }
    }
}
