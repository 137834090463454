.contact-us {

    .page-header {
        background: url(/images/contact-us-head-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 12rem 0;
        min-height: 71rem;

        .contact-us-header {
            font-size: 4.8rem;
            font-weight: bold;
            color: #FFF;
            line-height: 1.4;
        }

        .contact-us-text {
            font-size: 1.8rem;
            color: #FFF;
        }
    }

    .contact-us-content {

        &-header {
            font-size: 5rem;
            font-weight: bold;
        }

        &-text {
            font-size: 1.8rem;
            font-weight: 300;
            color: rgba($color: #000, $alpha: .7);
        }

        .contact-us-content-widget {
            padding: 3rem;
            min-height: 21.5rem;

            a {
                font-size: 1.6rem;
                color: #000;
                letter-spacing: 1.2px;
            }

            &.active {
                background-color: $primary-color;

                .section2-title,
                a {
                    color: #FFF;
                }
            }
        }
    }

    .contact-us-form {
        margin-top: 10rem;
        padding: 10rem 0;
        background-color: $primary-color;

        .contact-us-form-header {
            font-size: 4.5rem;
            font-weight: bold;
            color: #FFF;
        }

        .btn-secondary {
            float: inline-end;
            margin-top: 4rem;
            width: 35rem;
            height: 5.5rem;
            border-radius: .8rem;
            font-size: 1.6rem;
            letter-spacing: 8px;
            text-transform: uppercase;
        }

        .form-group {
            position: relative;

            label {
                color: #FFF;

            }

            .form-control {
                border: 1px solid $primary-color;
                background-color: transparent;
                width: 100%;
                height: 4.8rem;
                border-bottom: 1px solid #FFF;
                border-radius: 0;
                color: #FFF;
                transition: all ease-in .2s;

                &:focus {
                    border: 1px solid #FFF;

                }
            }
        }

        textarea {
            padding: 1rem;
        }
    }
}

.join-our-network {
    background-color: #ECEFF1;
    padding-bottom: 10rem;

    .container {
        background-color: $primary-color;
        border-radius: 3rem;
        width: 100%;
        min-height: 16rem;

        .form-control {
            width: 100%;
            height: 4.8rem;
        }

        .row {
            padding-top: 6rem;
            margin-top: 4rem;
        }

        .btn-primary {
            width: 15rem;
            height: 6rem;
            border-radius: 4rem;
            border: 1px solid #FFF;
            background-color: rgba($color: $primary-color, $alpha: .2);
            position: relative;
            top: -.6rem;
        }
    }
}