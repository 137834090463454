.faq {
    .page-header {
        background-color: #C0E3F4;
        width: 100%;
        height: 50rem;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    .accordion {
        border: 2px solid #464646;
        border-radius: .5rem;
        .card {
            padding: 2rem;
            border: 0;
        }
    }

    .card-header {
        background-color: unset;
        border-bottom: 0;
    }

    .accordion-collapse {
        padding-inline-start: 8.5rem;
    }

    .faq-header {
        font-size: 2.8rem;
        font-weight: bold;
        color: #002547;
        text-align: left;
    }

    .faq-plus-minus {
        background-color: $primary-color;
        min-width: 5rem;
        height: 5rem;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-body{
        color: #464646;
        font-size: 1.6rem;
        font-weight: 400;
    }
}