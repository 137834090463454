.header {
	width: 100%;
	min-height: 100px;
	padding: 1rem 0;
	z-index: 20;
	padding-bottom: 0;
	background: transparent;

	&.fixed {
		position: fixed;
		z-index: 1049;
		background: #FFF;
	}

	.container,
	.container-fluid {
		position: relative;

	}

	.header-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 80px;
	}

	.header-nav {
		.navbar {
			.navbar-collapse {
				align-items: center;
				justify-content: end;
				min-height: 75px;
			}

			.navbar-nav {
				justify-content: space-between;
				gap: 2rem;

				a {
					font-size: 1.5rem;
					font-weight: 500;

					&:hover {
						color: $secondary-color !important;
					}
				}
			}
		}

		.active {
			color: $secondary-color !important;
		}
	}

	&.sub-page {
		background: linear-gradient(90deg, rgba(9, 41, 91, 1) 0%, rgba(0, 146, 137, 1) 100%);
	}

	.header-btn {
		width: 150px;
		font-size: 1.4rem;
		font-weight: 500;
		border-radius: 0;
		// background-color: #FFF !important;
		color: #09295B !important;
		display: flex;
		justify-content: center;
		align-items: center;

		// &:hover {
		// 	color: #FFF !important;

		// 	&::after {
		// 		height: 70px !important;
		// 		left: -20rem !important;
		// 	}
		// }
	}

	.change-language {
		color: #FFF;
		font-weight: 600;
		width: 42px;
		height: 42px;
		border-radius: .8rem;
		overflow: hidden;

	}

	.change-theme {
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 32px;
		height: 32px;
		margin-top: .5rem;
		transition: all .3s ease-in-out;

		&.dark {
			background-image: url(/images/icons/dark-mode.svg);

			&:hover {
				background-image: url(/images/icons/dark-mode-hovered.svg);
			}
		}

		&.light {
			background-image: url(/images/icons/light-mode.svg);

			&:hover {
				background-image: url(/images/icons/light-mode-hovered.svg);
			}
		}

		&:hover {
			img {
				background-color: #ffffff;
			}
		}
	}

	.navbar-light {
		.navbar-toggler {
			border-color: #d9d9d9 !important;
		}

		.navbar-toggler-icon {
			background: none !important;
			position: relative;
			width: 30px;
			height: 30px;
			padding: 6px 2px 0;

			&::before {
				content: "";
				position: absolute;
				left: 4px;
				top: 7px;
				width: 24px;
				height: 2px;
				background-color: #d9d9d9;
				border-radius: 10px;
				box-shadow: 0 0.5em 0 0 #d9d9d9, 0 1em 0 0 #d9d9d9;
			}
		}
	}

	.condition {
		width: 15rem;
		height: 3rem;
		position: absolute;
		top: -.7rem;
		right: 3rem;
		display: flex;
		justify-content: space-between;

		p {
			font-size: 1.4rem;
			font-weight: 500;
		}
	}

	.condition-text {
		width: 12rem;
		display: flex;
		justify-content: space-between;
	}

	.copyright {
		padding: 3rem 0;
		text-align: center;
		border-top: 1px solid rgba(0, 0, 0, .1);

		p {
			font-size: 1.4rem;
			font-weight: 500;
		}
	}
}