.services {
    .page-header {
        background-color: #C0E3F4;
        width: 100%;
        height: 69rem;
        overflow: hidden;

    }

    .services-content{
        padding: 5rem 0;
        &-img-1{
            position: absolute;
            left: -6rem;
            z-index: -1;
        }
        &-img-2{
            position: absolute;
            right: -6rem;
            z-index: -1;
        }
        &-img-3{
            position: absolute;
            left: -6rem;
            bottom: -1rem;
            z-index: -1;
        }
    }

    .services-thanks-container {
        background-color: #FFF;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(#C0E3F4, .4) 100%);
        padding: 20rem 0;

        .services-thanks {
            background-color: #141720;
            border-radius: 3rem;
            height: 32rem;
            padding: 5rem;

            &-header {
                font-size: 3rem;
                font-weight: bold;
                color: #FFF;
                text-align: center;
                letter-spacing: 2px;
            }

            .services-thanks-img {
                position: relative;
                top: -5rem;
                right: -20rem;
            }

            .btn-primary {
                width: 19rem;
                height: 4.5rem;
                border-radius: 4rem;
                border: 1px solid #FFF;
                background-color: rgba($color: $primary-color, $alpha: .2);
                margin-top: 2rem;
            }
        }
    }

}