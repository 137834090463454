@keyframes mouse{
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(5px);
        opacity: .5;
    }
    100%{
        transform: translateY(10px);
        opacity: 0;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
@keyframes reverserotation {
  from {
    transform: rotate(360deg);
  }
  
  to {
    transform: rotate(0deg);
  }
}

/* Animation for lazyload */
@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Animation for Skeleton Loading */
  @keyframes skeletonloading {
    to {
      background-position: 400% 0
    }
  }

  @keyframes drop{
    0%{
      top: 10%
    }
    100%{
      top: 110%
    }
}
